<template>
  <div class="debts-ship-fee-orders">
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <!-- MAIN CONTAINER -->
    <b-row>
      <b-col md="12" lg="12" sm="12">
        <KTCodePreview v-bind:title="' '">
          <template v-slot:toolbar>
            <div class="row">
              <div>
                <b-dropdown size="sm" id="dropdown-1" right>
                  <template slot="button-content">
                    <i style="font-size: 1rem" class="fas fa-cog"></i>
                    <span class="font-weight-bolder">Thao tác</span>
                  </template>
                  <b-dropdown-item @click="excelModal" v-show="false">
                    <span>
                      <i style="font-size: 1rem" class="far fa-file-excel"></i>
                      &nbsp; Xuất Excel</span
                    >
                  </b-dropdown-item>
                  <b-dropdown-item
                    :to="{
                      name: 'import-debt',
                      params: {
                        mode: 'shipfee',
                      },
                    }"
                    v-if="isShow(['TRANSACTION_IMPORT_EXCEL'])"
                  >
                    <span style="color: black">
                      <i style="font-size: 1rem" class="far fa-file-excel"></i>
                      &nbsp; Nhập từ Excel</span
                    >
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </template>
          <!-- CONTENT -->
          <template v-slot:preview>
            <!-- FILTER CONTENT -->
            <b-row class="mb-5">
              <b-col>
                <b-form>
                  <Autosuggest
                    :model="searchStock"
                    :suggestions="filteredOptions"
                    placeholder="cửa hàng"
                    :limit="10"
                    @select="onSelected"
                    @change="onInputChangeStore"
                    :disabled="isDisabled"
                  />
                </b-form>
              </b-col>
              <b-col>
                <b-input-group>
                  <div class="date-style">
                    <b-input-group>
                      <date-picker
                        placeholder="Từ"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="apiParams.fromDate"
                      ></date-picker>
                    </b-input-group>
                    <span class="ml-1 mr-1"></span>
                    <b-input-group>
                      <date-picker
                        placeholder="Đến"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="apiParams.toDate"
                      ></date-picker>
                    </b-input-group>
                  </div>
                </b-input-group>
              </b-col>
              <b-col>
                <b-input
                  placeholder="Mã HĐ"
                  v-model="apiParams.contractCode"
                  size="sm"
                />
              </b-col>
              <b-col>
                <b-input
                  placeholder="Chứng từ"
                  v-model="apiParams.docId"
                  size="sm"
                />
              </b-col>
              <b-col>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="onFilter()"
                  >Lọc</b-button
                >
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <b-form>
                  <b-input
                    placeholder="Tìm chính xác SĐT KH"
                    v-model="apiParams.customerMobile"
                    size="sm"
                  />
                </b-form>
              </b-col>
              <b-col>
                <b-form>
                  <b-form-select
                    v-model="apiParams.isFullPaid"
                    :options="optionPaidStatus"
                    size="sm"
                    class="select-style"
                  ></b-form-select>
                </b-form>
              </b-col>
              <b-col>
                <b-form>
                  <b-form-select
                    v-model="apiParams.contactId"
                    :options="listInstallments"
                    size="sm"
                    class="select-style"
                  ></b-form-select>
                </b-form>
              </b-col>
              <b-col></b-col>
              <b-col></b-col>
            </b-row>
            <!-- LIST DATA -->
            <b-row>
              <b-col md="12" lg="12" sm="12">
                <b-table
                  :items="mainList.dataset"
                  :fields="tableFields"
                  :busy="onLoadingList"
                  class="table-bordered table-hover"
                >
                  <template v-slot:table-busy>
                    <vcl-table :speed="5" :animate="true" :columns="10" />
                  </template>
                  <!-- Format id column -->
                  <template v-slot:cell(createdAt)="row">
                    <span style="display: block">{{
                      row.item.createdAt && formatDate(row.item.createdAt, dpConfigs.date.format)
                    }}</span>
                  </template>
                  <!-- Format type column -->
                  <template v-slot:cell(installMoneyAccountId)="row">
                    <span style="display: block">{{
                      row.item.installmentName
                    }}</span>
                    <span style="display: block">
                      <strong> {{ row.item.storeName }} </strong>
                    </span>
                  </template>
                  <!-- Format accountant column -->
                  <template v-slot:cell(id)="row">
                    <router-link
                      :to="{
                        path: `/orders/edit-order/${row.item.id}`,
                      }"
                      target="_blank"
                      >{{ row.item.id }}
                    </router-link>
                  </template>
                  <template v-slot:cell(customerShipFee)="row">
                    {{ convertPrice(row.item.customerShipFee) }}
                  </template>
                  <template v-slot:cell(paidAmount)="row">
                    {{ convertPrice(row.item.paidAmount) }}
                  </template>
                  <template v-slot:cell(debtAmount)="row">
                    {{ convertPrice(row.item.debtAmount) }}
                  </template>
                  <template v-slot:cell(actions)="row">
                    <div
                      class="d-flex justify-content-center"
                      v-if="row.item.debtAmount > 0"
                    >
                      <b-dropdown size="sm" id="dropdown-left" right no-caret>
                        <b-dropdown-item
                          o-caret
                          @click="editModalPayment(row.item)"
                        >
                          <span style="color: #3f4254; font-size: 12px">
                            <i
                              class="flaticon2-pen"
                              style="font-size: 1rem"
                            ></i>
                            &nbsp; Chỉnh sửa
                          </span>
                        </b-dropdown-item>
                        <template slot="button-content">
                          <i
                            style="font-size: 1rem; padding-right: 0px"
                            class="flaticon2-settings"
                          ></i>
                        </template>
                        <b-dropdown-item>
                          <router-link
                            :to="{
                              name: 'transaction',
                              params: {
                                mainModelProp: {
                                  contactType: '22',
                                  reconciliationDocType: '2',
                                  reconciliationDocId: row.item.id,
                                  type: '1',
                                  amount: row.item.debtAmount,
                                  note: getNoteForPay(row.item),
                                },
                                mode: 'cash',
                                action: 'add',
                                id: 'new',
                              },
                            }"
                          >
                            <span style="color: #3f4254; font-size: 12px">
                              <i
                                style="font-size: 1rem"
                                class="far fa-money-bill-alt"
                              ></i>
                              &nbsp; Thanh toán bằng tiền mặt
                            </span>
                          </router-link>
                        </b-dropdown-item>
                        <b-dropdown-item>
                          <router-link
                            :to="{
                              name: 'transaction',
                              params: {
                                mainModelProp: {
                                  contactType: '22',
                                  reconciliationDocType: '2',
                                  reconciliationDocId: row.item.id,
                                  type: '3',
                                  amount: row.item.debtAmount,
                                  note: getNoteForPay(row.item),
                                },
                                mode: 'bank',
                                action: 'add',
                                id: 'new',
                              },
                            }"
                          >
                            <span style="color: #3f4254; font-size: 12px">
                              <i
                                style="font-size: 1rem"
                                class="fas fa-money-check-alt"
                              ></i>
                              &nbsp; Thanh toán bằng CK ngân hàng
                            </span>
                          </router-link>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </template>
                  <template slot="bottom-row">
                    <td colspan="6" class="text-right font-weight-bolder">
                      <span>Tổng</span>
                    </td>
                    <td class="text-right font-weight-bolder">
                      <span>{{
                        convertPrice(sumAmount('customerShipFee'))
                      }}</span>
                    </td>
                    <td class="text-right font-weight-bolder">
                      <span>{{ convertPrice(sumAmount('paidAmount')) }}</span>
                    </td>
                    <td class="text-right font-weight-bolder">
                      <span>{{ convertPrice(sumAmount('debtAmount')) }}</span>
                    </td>
                    <td v-if="isShow(['TRANSACTION_INSERT'])"></td>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <!-- PAGINATION -->
            <b-row>
              <b-col lg="3" md="3" sm="12">
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số:
                  {{ mainList.total }}
                </p>
              </b-col>
              <b-col lg="9" md="9" sm="12">
                <b-pagination-nav
                  class="custom-pagination"
                  v-show="mainList.pages >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="mainList.pages"
                  use-router
                  @change="fetchMainData()"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm m-1"
                  prev-class="page-item-prev btn btn-icon btn-sm m-1"
                  next-class="page-item-next btn btn-icon btn-sm m-1 "
                  last-class="page-item-last btn btn-icon btn-sm m-1 "
                  page-class="btn btn-icon btn-sm border-0 m-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
          </template>
        </KTCodePreview>
      </b-col>
    </b-row>
    <b-modal ref="excel-modal" hide-footer title="Xuất excel" id="excel-modal">
      <v-form ref="form" lazy-validation>
        <div class="mb-4">
          <div class="row">
            <div class="col-6">
              <b-form-group label="Chọn cột cần xuất:">
                <b-form-radio-group
                  id="radio-group-excel-1"
                  v-model="excel.selectedRowExcel"
                  name="radio-sub-component-1"
                  @change.native="showHeader"
                >
                  <b-form-radio value="all">Tất cả</b-form-radio>
                  <b-form-radio value="row">Chọn cột</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group label="Danh sách cần xuất:">
                <b-form-radio-group
                  id="radio-group-excel-2"
                  v-model="excel.selectedListExcel"
                  name="radio-sub-component-2"
                >
                  <b-form-radio value="current">Trang hiện tại</b-form-radio>
                  <b-form-radio value="all">Tất cả các trang</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>
            <div class="col-6" v-show="excel.showHeaderExcel">
              <b-form-group>
                <template #label>
                  Cột:<br />
                  <b-form-checkbox
                    v-model="excel.allSelected"
                    :indeterminate="excel.indeterminate"
                    aria-describedby="items"
                    aria-controls="items"
                    @change="toggleAll"
                  >
                    {{ excel.allSelected ? 'Bỏ chọn tất cả' : 'Tất cả' }}
                  </b-form-checkbox>
                </template>
                <b-form-checkbox-group
                  id="items"
                  v-model="excel.selectedHeader"
                  :options="excel.optionsHeader"
                  name="items"
                  stacked
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>
        </div>

        <b-button
          variant="primary"
          size="sm"
          @click="reportClick($event)"
          class="mr-3"
          ref="kt_excel"
        >
          <v-icon small v-show="!btnDisable" class="text-white" v-b-tooltip
            >mdi-download</v-icon
          >
          <strong>Xuất Excel</strong>
        </b-button>

        <b-button
          style="width: 100px"
          variant="secondary"
          size="sm"
          @click="$bvModal.hide('excel-modal')"
        >
          <strong>Hủy</strong>
        </b-button>
      </v-form>
    </b-modal>
    <DebtEditModal
      :item="order"
      :mode="mbPaymentType"
      v-on:refresh="fetchMainData"
    />
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Loader from '@/view/content/Loader.vue';
import { VclTable } from 'vue-content-loading';
import ApiService from '@/core/services/api.service';
import { cmdUrl } from '@/utils/apiUrl';
import { getToastInstance } from '@/utils/toastHelper';
import { mapGetters } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import { checkPermissions } from '@/utils/saveDataToLocal';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import axios from 'axios';
import { BASE_URL } from '@/utils/constants';
import DebtEditModal from '@/view/components/debt/DebtEditModal.vue';
import { getSubtractDate, getAddDate, formatDate } from '@/utils/date';
import { convertPrice } from '@/utils/common';
const thStyleCommon = {
  textAlign: 'center',
  fontWeight: 600,
  color: '#181c32',
  width: '5%',
};
const MIN_RANGE_DATE = getSubtractDate(7);
const MAX_RANGE_DATE = getAddDate();

export default {
  data() {
    return {
      title: 'Công nợ trả góp phí ship theo ngày',
      listInstallments: [{ value: '', text: 'Tất cả dịch vụ trả góp' }],
      optionPaidStatus: [
        { value: '', text: 'Công nợ trả góp' },
        { value: 'true', text: 'Hết nợ' },
        { value: 'false', text: 'Còn nợ' },
      ],
      mainList: {
        dataset: [],
        total: 0,
        pages: 1,
      },
      apiParams: {
        page: 1,
        pageSize: 10,
        storeId: '',
        fromDate: MIN_RANGE_DATE,
        toDate: MAX_RANGE_DATE,
        contactId: '',
        contractCode: null,
        docId: null,
        isFullPaid: '',
      },
      onLoadingList: false,
      tableFields: [
        {
          key: 'createdAt',
          label: 'Ngày',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '50px',
          },
          tdClass: 'text-center',
        },
        {
          key: 'installMoneyAccountId',
          label: 'Cửa hàng',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '15%',
          },
        },
        {
          key: 'contractCode',
          label: 'Mã hợp đồng',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: 'calc(27% - 120px)',
          },
        },
        {
          key: 'customerName',
          label: 'Khách hàng',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'customerMobile',
          label: 'Điện thoại',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '8%',
          },
        },
        {
          key: 'id',
          label: 'Chứng từ',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '8%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'customerShipFee',
          label: 'Phí ship',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '8%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'paidAmount',
          label: 'Đã thanh toán',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '8%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'debtAmount',
          label: 'Còn nợ',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '8%',
          },
          tdClass: 'text-right',
        },
        {
          key: checkPermissions('TRANSACTION_INSERT') ? 'actions' : '',
          label: '',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '50px',
          },
        },
      ],
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      filteredOptions: [],
      optionsStore: [
        {
          data: [],
        },
      ],
      searchStock: '',
      isDisabled: false,
      excel: {
        selectedRowExcel: 'all',
        selectedListExcel: 'current',
        showHeaderExcel: false,
        indeterminate: false,
        allSelected: false,
        optionsHeader: [],
        selectedHeader: [],
        optionsHeaderDefault: [],
      },
      btnDisable: false,
      order: {
        orderId: null,
      },
      mbPaymentType: null,
    };
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
    Autosuggest,
    DebtEditModal,
  },
  mounted() {
    window.addEventListener('keyup', this.onFilterKeyEnter);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onFilterKeyEnter);
  },
  created() {
    this.getFillter();
    this.loadBreadcum();
    this.fetchInstallments();
    this.fetchStoreByUser();
    this.onFilter();
  },
  methods: {
    convertPrice,
    ...getToastInstance(),
    formatDate,
    onFilterKeyEnter(event) {
      if (event.key === 'Enter') {
        this.onFilter();
      }
    },
    linkGen(pageNum) {
      let param = this.getParamFilters();
      param = _.omitBy(param, _.isNil);
      param.page = pageNum || 1;
      return {
        path: '/accounting/debts/ship-fee-orders',
        query: param,
      };
    },
    onFilter() {
      this.onChangeUrl();
      this.fetchMainData();
    },
    onChangeUrl() {
      this.$route.query.page = 1;

      let param = this.setFillter();
      param = _.omit(param, ['page', 'pageSize']);
      console.log('param', param);
    },
    setFillter() {
      const param = this.getParamFilters();

      return param;
    },
    getParamFilters() {
      return {
        page: this.apiParams.page,
        pageSize: this.apiParams.pageSize,
        storeId: this.apiParams.storeId,
        contactName: this.selectedCreditAcc,
        fromDate: this.apiParams.fromDate
          ? moment(this.apiParams.fromDate, 'DD/MM/YYYY').format('DD/MM/YYYY')
          : null,
        toDate: this.apiParams.toDate
          ? moment(this.apiParams.toDate, 'DD/MM/YYYY').format('DD/MM/YYYY')
          : null,
        contractCode: this.apiParams.contractCode,
        docId: this.apiParams.docId,
        customerMobile: this.apiParams.customerMobile,
        isFullPaid: this.apiParams.isFullPaid || null,
      };
    },
    getFillter() {
      const query = this.$route.query || null;

      if (!_.isEmpty(query)) {
        const storeId = query.searchStock || null;

        const fromDate = query.fromDate || null;

        const toDate = query.toDate || null;

        const contactName = query.contactName || null;

        const contractCode = query.contractCode || null;

        const docId = query.docId || null;

        const customerMobile = query.customerMobile || null;

        const isFullPaid = query.isFullPaid || null;

        const page = query.page || 1;

        const pageSize = query.pageSize || 10;

        this.selectedCreditAcc = contactName;

        this.apiParams = Object.assign(
          {},
          {
            page,
            pageSize,
            storeId,
            fromDate,
            toDate,
            contractCode,
            docId,
            customerMobile,
            isFullPaid,
          }
        );
      }
    },
    editModalPayment: function (item) {
      this.mbPaymentType = 'installment-orders';
      this.order.orderId = item.id;
      this.$bvModal.show('md-debt');
    },
    fetchMainData: async function () {
      let page = this.$route.query.page || 1;
      if (!page) {
        page = 1;
      }
      this.apiParams.page = page;
      this.apiParams.type = this.$route.params.mode;
      if (this.searchStock.trim() === '') {
        this.apiParams.storeId = null;
      }

      this.onLoadingList = true;
      ApiService.query(cmdUrl.DebtUrl.shipFeeOrders, {
        params: { ...this.apiParams },
      })
        .then((response) => {
          const respData = response.data.data;
          this.mainList = respData;
          this.onLoadingList = false;
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFailure(message);
          this.onLoadingList = false;
        });
    },
    loadBreadcum: function () {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Kế toán', route: 'accountant' },
        { title: 'Công nợ' },
        { title: this.title },
      ]);
    },
    fetchInstallments: async function () {
      let params = {
        page: 1,
        pageSize: 1000,
      };
      await ApiService.query('/installments', { params }).then((response) => {
        const listData = response.data.data.result;
        _.map(listData, (item) => {
          this.listInstallments.push({
            value: item.id,
            text: item.name,
          });
        });
      });
    },
    sumAmount(key) {
      const outItems = this.mainList.dataset;
      return _.reduce(
        outItems,
        (sum, item) => {
          return sum + parseFloat(item[key]);
        },
        0
      );
    },
    getNoteForPay: function (item) {
      let note = `Nhận thanh toán từ dịch vụ trả góp\n`;
      note += `Mã HĐ: ${item.id}\n`;
      note += `Mã hợp đồng: ${item.contractCode}`;
      return note;
    },
    isShow(conditions) {
      return checkPermissions(conditions);
    },
    onSelected(option) {
      this.searchStock = option.item.name;
      this.apiParams.storeId = option.item.id;
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
      }
      this.searchStock = text;

      const filteredData = this.optionsStore[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, 10);

      this.filteredOptions = [...filteredData];
    },
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.searchStock = stores[0].name;
            this.apiParams.storeId = stores[0].id;
            this.isDisabled = true;
          } else {
            this.optionsStore[0].data = [];
            const data = {
              id: '',
              name: 'Tất cả cửa hàng',
              shortName: 'Tat ca cua hang',
            };
            this.optionsStore[0].data.push(data);
            stores.map((element) => {
              this.optionsStore[0].data.push(element);
            });
            this.filteredOptions = [...this.optionsStore[0].data];
            this.searchStock = data.name;
            this.apiParams.storeId = data.id;
          }
        }
        this.fetchMainData();
      });
    },
    excelModal: async function () {
      this.excel.optionsHeader = [
        {
          text: 'Ngày tạo',
          value: 'date',
          disabled: false,
          selected: true,
        },
        {
          text: 'Cửa hàng',
          value: 'storeName',
          disabled: false,
          selected: true,
        },
        {
          text: 'Dịch vụ trả góp',
          value: 'installMoneyAccountName',
          disabled: false,
          selected: true,
        },
        {
          text: 'Mã hợp đồng',
          value: 'contractCode',
          disabled: false,
          selected: true,
        },
        {
          text: 'Tên khách hàng',
          value: 'customerName',
          disabled: false,
          selected: true,
        },
        {
          text: 'SĐT khách hàng',
          value: 'customerMobile',
          disabled: false,
          selected: true,
        },
        {
          text: 'Chứng từ',
          value: 'id',
          disabled: false,
          selected: true,
        },
        {
          text: 'Tiền hàng',
          value: 'totalAmount',
          disabled: false,
          selected: true,
        },
        {
          text: 'Chiết khấu',
          value: 'discountAmount',
          disabled: false,
          selected: true,
        },
        {
          text: 'Tiền trả góp',
          value: 'payAmount',
          disabled: false,
          selected: true,
        },
        {
          text: 'Đã thanh toán',
          value: 'paidAmount',
          disabled: false,
          selected: true,
        },
        {
          text: 'Còn nợ',
          value: 'debtAmount',
          disabled: false,
          selected: true,
        },
        {
          text: 'TK thu phí quẹt thẻ',
          value: 'creditFeeAcountName',
          disabled: false,
          selected: true,
        },
        {
          text: 'Hình thức thu phí quẹt thẻ',
          value: 'payCreditFeeTypeName',
          disabled: false,
          selected: true,
        },
        {
          text: 'Số tiền TK thu phí quẹt thẻ',
          value: 'otherFees',
          disabled: false,
          selected: true,
        },
      ];
      this.excel.optionsHeaderDefault = this.excel.optionsHeader.filter(
        (x) => x.default
      );
      this.excel.selectedHeader = this.excel.optionsHeader
        .filter((x) => x.selected)
        .map((x) => x.value);

      this.$refs['excel-modal'].show();
    },
    reportClick: async function () {
      const submitButton = this.$refs['kt_excel'];
      submitButton.classList.add(
        'spinner',
        'spinner-light',
        'spinner-left',
        'spinner-sm'
      );
      this.btnDisable = true;
      let key = this.$uuid.v4();

      let customsChids = this.excel.optionsHeader.filter((x) => x.customs);

      if (customsChids.length > 0) {
        customsChids.forEach((element) => {
          let index = this.excel.selectedHeader.findIndex(
            (x) => x === element.value
          );
          if (index > -1) {
            let childs = element.customs.map((x) => x.value);
            this.excel.selectedHeader.splice(index, 1);
            this.excel.selectedHeader =
              this.excel.selectedHeader.concat(childs);
          }
        });
      }

      let data = {
        headers: this.excel.selectedHeader,
        params: {},
        paramSearchs: {},
      };

      if (this.excel.selectedRowExcel !== 'row') {
        let selectAll = this.excel.optionsHeader.map((x) => x.value);

        if (customsChids.length > 0) {
          customsChids.forEach((element) => {
            let index = selectAll.findIndex((x) => x === element.value);
            if (index > -1) {
              let childs = element.customs.map((x) => x.value);
              selectAll.splice(index, 1);
              selectAll = selectAll.concat(childs);
            }
          });
        }
        data.headers = selectAll;
      }

      let totalPage =
        this.excel.selectedListExcel === 'current'
          ? parseInt(this.$route.query.page || 1)
          : Math.ceil(this.mainList.total / 150);

      let i =
        this.excel.selectedListExcel === 'current'
          ? parseInt(this.$route.query.page || 1)
          : 1;

      for (i; i <= totalPage; i++) {
        let params = Object.assign({}, this.apiParams);

        params.page = i;
        if (this.excel.selectedListExcel !== 'current') params.pageSize = 150;

        let path = this.$route.path;
        params.mode = path.substr(path.lastIndexOf('/') + 1);

        if (this.searchStock.trim() === '') {
          params.storeId = null;
        }

        data.params = {
          status: this.excel.selectedListExcel,
          totalPage: totalPage,
          currentPage: i,
          key: key,
        };

        data.paramSearchs = params;
        let result = await this.exportExcel(data);

        if (!result) return;

        if (data.params.totalPage === data.params.currentPage) {
          data.params.status = 'download';
          await this.exportExcel(data);
        }
      }
      this.btnDisable = false;
      submitButton.classList.remove(
        'spinner',
        'spinner-light',
        'spinner-right'
      );
      this.$bvModal.hide('excel-modal');
    },
    exportExcel(data) {
      let baseUrl = BASE_URL;
      let url = `${baseUrl}debts/export-excel`;
      let options = { url: url, method: 'POST', data: data };

      if (data.params.status === 'download') {
        options.responseType = 'blob';
      }

      return axios(options)
        .then((response) => {
          if (data.params.status === 'download') {
            let headerLine = response.headers['content-disposition'];
            let startFileNameIndex = headerLine.indexOf('"') + 1;
            let endFileNameIndex = headerLine.lastIndexOf('"');
            let filename = headerLine.substring(
              startFileNameIndex,
              endFileNameIndex
            );
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', filename);
            document.body.appendChild(fileLink);

            fileLink.click();
          } else {
            return response;
          }
        })
        .catch(() => {
          this.makeToastFailure('Xuất Excel không thành công');
        });
    },
    showHeader() {
      if (this.excel.selectedRowExcel === 'row') {
        this.excel.showHeaderExcel = true;
      } else {
        this.excel.showHeaderExcel = false;
      }
    },
    toggleAll(checked) {
      this.excel.selectedHeader = checked
        ? this.excel.optionsHeader.map((x) => x.value)
        : this.excel.optionsHeaderDefault.map((x) => x.value);
    },
  },

  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
  beforeRouteUpdate: function (to, from, next) {
    this.mode = to.params.mode;
    next();
  },
  watch: {
    'excel.selectedHeader': {
      handler: function (newVal) {
        if (newVal.length === 0) {
          this.excel.indeterminate = false;
          this.excel.allSelected = false;
        } else if (newVal.length === this.excel.optionsHeader.length) {
          this.excel.indeterminate = false;
          this.excel.allSelected = true;
        } else {
          this.excel.indeterminate = true;
          this.excel.allSelected = false;
        }
      },
      deep: true,
    },
    mode: function (val) {
      this.mode = val;
      this.fetchMainData();
      this.loadBreadcum();
    },
  },
};
</script>

<style lang="scss">
.debts-ship-fee-orders {
  .icon:hover {
    background-color: #90c6fc;
  }

  .sttClass {
    width: 20px;
  }

  input {
    border: 1px solid #ced4da;
  }
  .date-style {
    display: flex;
    align-items: center;
  }
  .page-item.disabled {
    cursor: not-allowed;
    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }
}
</style>
